import { Fragment, useEffect, useState } from "react";
import { Alert, Badge, Button, Col, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import { Highlighter, Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead";
import {
  RenderMenuProps,
} from "react-bootstrap-typeahead/types/components/Typeahead/Typeahead";
import { TypeaheadManagerChildProps } from "react-bootstrap-typeahead/types/types";
import axios from "axios";

function EditDuties({
  className,
  token,
  duties,
  setDuties,
}: {
  className?: string;
  token: string;
  duties: any;
  setDuties: any;
}) {
  const [showEditModal, setShowEditModal] = useState(false);
  const [editDuties, setEditDuties] = useState<any>([]);
  const [selectedDuty, setSelectedDuty] = useState<any>(undefined);
  const [changeCount, setChangeCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();

  useEffect(() => {
    // Deep copy
    setEditDuties(JSON.parse(JSON.stringify(duties)));
  }, [duties]);

  useEffect(() => {
    compareChanges();
  }, [editDuties]);

  const hideEditModal = () => {
    setShowEditModal(false);
    setSelectedDuty(undefined);
    setEditDuties(JSON.parse(JSON.stringify(duties)));
    setError(undefined);
    setChangeCount(0);
  };

  const groupBy = function(xs: any, key: string) {
    return xs.reduce(function(rv: any, x: any) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  const renderMenu = (
    items: any,
    menuProps: RenderMenuProps,
    state: TypeaheadManagerChildProps
  ) => {
    let index = 0;
    const duties = groupBy(items, "is_unknown");
    const fragment = Object.keys(duties)
      .sort()
      .reverse()
      .map((is_duty_unknown) => (
        <Fragment key={is_duty_unknown}>
          {index !== 0 && <Menu.Divider />}
          <Menu.Header>{is_duty_unknown == "true" ? "Unbekannte Dienste" : "Dienste bearbeiten"}</Menu.Header>
          {duties[is_duty_unknown].map((i: any) => {
            const item = (
              <MenuItem key={index} option={i.id} position={index}>
                <Highlighter search={state.text}>{i.id}</Highlighter>
              </MenuItem>
            );

            index += 1;
            return item;
          })}
        </Fragment>
      ));

    return <Menu {...menuProps}>{fragment}</Menu>;
  };

  const compareChanges = (): string[] => {
    let changedDuties: string[] = [];
    Object.keys(editDuties).forEach((duty) => {
      const keys = new Set<string>;
      Object.keys(editDuties[duty]).forEach((key) => {
        keys.add(key);
      });
      Object.keys(duties[duty]).forEach((key) => {
        keys.add(key);
      });

      let foundChanges = false;
      keys.forEach((key) => {
        if (editDuties[duty][key] !== duties[duty][key]) {
          foundChanges = true;
        }
      });
      if (foundChanges)
        changedDuties.push(duty);
    });

    setChangeCount(changedDuties.length);
    return changedDuties;
  };

  const editSelectedDuty = (prop: string, value: string | boolean | null) => {
    const new_state = {...editDuties};
    new_state[selectedDuty][prop] = value;
    new_state[selectedDuty]["is_unknown"] = false;
    setEditDuties(new_state);
  };

  const saveChanges = () => {
    setLoading(true);
    setError(undefined);

    const changed_duties = compareChanges();
    const updated_duties: any = {};
    changed_duties.forEach((duty) => {
      updated_duties[duty] = editDuties[duty];
    });

    const params = new URLSearchParams();
    params.set("token", token);

    axios
      .post(window.__RUNTIME_CONFIG__.API_URL + "duties?" + params.toString(), updated_duties)
      .then((response) => {
        setDuties(response.data);
        hideEditModal();
      })
      .catch((error) => {
        setError(error.response.data || error.response || error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const unknown_edit_duties_amount = Object.keys(editDuties).filter((duty) => editDuties[duty]["is_unknown"]).length;
  const unknown_duties_amount = Object.keys(duties).filter((duty) => duties[duty]["is_unknown"]).length;

  return (
    <>
      <Button
        className={className}
        onClick={() => {
          setShowEditModal(true);
        }}
      >
        <FontAwesomeIcon icon={faEdit} />
        <span> Dienste bearbeiten</span>
        {
          unknown_duties_amount > 0 ? (
            <Badge pill bg="danger" className="ms-1">
              {unknown_duties_amount}
            </Badge>
          ) : null
        }
      </Button>

      <Modal
        show={showEditModal}
        onHide={hideEditModal}
        backdrop={loading ? "static" : undefined}
      >
        <Modal.Header closeButton={true}>
          <Modal.Title>Dienste bearbeiten</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            unknown_edit_duties_amount > 0 ? (
              <p className="text-danger">
                Es existieren noch <b>{unknown_edit_duties_amount}</b> unbekannte Dienste!<br/>
                Unbekannte Dienste werden nicht in die Kalender übernommen!
              </p>
            ) : null
          }
          <Typeahead
            id="edit-duties"
            options={Object.keys(editDuties).map((duty) => Object.assign({id: duty}, editDuties[duty]))}
            flip
            placeholder="Dienst auswählen..."
            labelKey="id"
            clearButton
            renderMenu={renderMenu}
            onChange={(selected) => {
              if (selected.length == 0)
                setSelectedDuty(undefined);
              else {
                const duty = selected[0] as string;
                setSelectedDuty(duty);
                if (editDuties[duty]["is_unknown"]) {
                  const new_state = {...editDuties};
                  new_state[duty]["is_unknown"] = false;
                  new_state[duty]["is_full_day"] = true;
                  new_state[duty]["enable_reminder"] = true;
                  setEditDuties(new_state);
                }
              }
            }}
            className="mb-3"
          />

          {selectedDuty !== undefined && selectedDuty.length != 0 ? (
            <Form.Group>
              <Row className='mb-3'>
                <Col>
                  <Form.Label>Dienstname</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={selectedDuty}
                    onChange={(e) => {editSelectedDuty("name", e.target.value)}}
                    value={editDuties[selectedDuty]["name"]}
                  />
                </Col>
                <Col>
                  <Form.Label>Ablösung</Form.Label>
                  <Typeahead
                    id="edit-replacement"
                    options={Object.keys(editDuties).map((duty) => Object.assign({id: duty}, editDuties[duty]))}
                    flip
                    placeholder="Dienst auswählen..."
                    labelKey="id"
                    clearButton
                    selected={editDuties[selectedDuty]["replacement_duty"] ? [editDuties[selectedDuty]["replacement_duty"]] : []}
                    onChange={(selected) => {
                      if (selected.length == 0)
                        editSelectedDuty("replacement_duty", null);
                      else
                        editSelectedDuty("replacement_duty", selected[0] as string);
                    }}
                  />
                </Col>
              </Row>
              {
                !editDuties[selectedDuty]["is_full_day"] ? (
                  <Row className="mb-3">
                    <Col>
                      <Form.Label>Startzeit</Form.Label>
                      <Form.Control
                        type="time"
                        placeholder=""
                        onChange={(e) => {editSelectedDuty("start", e.target.value)}}
                        value={editDuties[selectedDuty]["start"]}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Endzeit</Form.Label>
                      <Form.Control
                        type="time"
                        placeholder=""
                        onChange={(e) => {editSelectedDuty("end", e.target.value)}}
                        value={editDuties[selectedDuty]["end"]}
                      />
                    </Col>
                  </Row>
                ) : null
              }
              <Row className='mb-0'>
                <Col>
                  <Form.Check 
                    type="checkbox"
                    label="Unbestimmter Zeitraum"
                    onChange={(e) => {editSelectedDuty("is_full_day", e.target.checked)}}
                    checked={editDuties[selectedDuty]["is_full_day"] || false}
                  />
                </Col>
                <Col>
                  <Form.Check 
                    type="checkbox"
                    label="Keine Erinnerung"
                    onChange={(e) => {editSelectedDuty("enable_reminder", !e.target.checked)}}
                    checked={!editDuties[selectedDuty]["enable_reminder"] || false}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Check 
                    type="checkbox"
                    label="Dienst ignorieren"
                    onChange={(e) => {editSelectedDuty("is_ignored", e.target.checked)}}
                    checked={editDuties[selectedDuty]["is_ignored"] || false}
                  />
                </Col>
                <Col>
                  <Form.Check 
                    type="checkbox"
                    label="Für andere unsichtbar"
                    onChange={(e) => {editSelectedDuty("is_invisible", e.target.checked)}}
                    checked={editDuties[selectedDuty]["is_invisible"] || false}
                  />
                </Col>
              </Row>
            </Form.Group>
          ) : null}

          {error !== undefined ? (
            <Alert variant="danger" className="mt-3 mb-0">
              <Alert.Heading>Es ist ein Fehler aufgetreten!</Alert.Heading>
              <p>
                <i>{error.message}</i>
              </p>
              <p className="mb-0">Bitte versuche es später erneut!</p>
            </Alert>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            disabled={loading}
            onClick={hideEditModal}
          >
            Schließen
          </Button>
          <Button disabled={loading || changeCount == 0} onClick={saveChanges}>
            {loading ? (
              <FontAwesomeIcon icon={faSpinner} spinPulse />
            ) : (
              <FontAwesomeIcon icon={faEdit} />
            )}
            <span> Dienste Speichern</span>
            {
              changeCount > 0 ? (
                <Badge pill bg="success" className="ms-1">
                  {changeCount}
                </Badge>
              ) : null
            }
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditDuties;
