import React, { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Typeahead } from 'react-bootstrap-typeahead';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleQuestion,
  faCopy,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import axios from 'axios';
import '../styles/big-calendar.scss';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/de';

function IcalSetup({ token }: { token: string }) {
  const [nameSelection, setNameSelection] = useState<any>([]);
  const [reminderInput, setReminderInput] = useState<string>('');
  const [showReminderTooltip, setShowReminderTooltip] = useState(false);
  const [icalURL, setIcalURL] = useState<string | undefined>(undefined);
  const [copySuccess, setCopySuccess] = useState(false);
  const [names, setNames] = useState<string[]>([]);
  const [events, setEvents] = useState<any[]>([]);

  const reminder_input_help = useRef(null);
  const ical_url_field = useRef(null);

  moment.locale('de');
  const localizer = momentLocalizer(moment);

  useEffect(() => {
    const params = new URLSearchParams();
    params.set('token', token);
    axios
      .get(window.__RUNTIME_CONFIG__.API_URL + 'personell?' + params.toString())
      .then((response) => setNames(response.data));
  }, []);

  useEffect(() => {
    const params = new URLSearchParams();
    if (token) params.set('token', token);
    if (reminderInput.length > 0 && reminderInput !== '0')
      params.set('reminder', reminderInput);

    if (nameSelection.length > 0)
      setIcalURL(
        window.__RUNTIME_CONFIG__.API_URL +
          'schedules/' +
          encodeURIComponent(nameSelection) +
          '?' +
          params.toString()
      );
    else setIcalURL(undefined);
  }, [nameSelection, reminderInput]);

  useEffect(() => {
    if (nameSelection.length === 0) {
      setEvents([]);
      return;
    }

    const params = new URLSearchParams();
    params.set('format', 'simple');
    if (token) params.set('token', token);

    axios
      .get(
        window.__RUNTIME_CONFIG__.API_URL +
          'schedules/' +
          encodeURIComponent(nameSelection) +
          '?' +
          params.toString()
      )
      .then((response) => {
        const events = response.data.map((event: any) => {
          return {
            title: event.duty,
            start: new Date(event.date),
            end: new Date(event.date),
            allDay: true,
          };
        });
        setEvents(events);
      }).catch((error) => {
        setEvents([]);
      });
  }, [nameSelection]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(icalURL ?? '');
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 4000);
  };

  const params = new URLSearchParams();
  if (token) params.set('token', token);
  return (
    <div className="appContainer col-11 col-sm-9 col-md-8 bg-light rounded-4 m-auto mt-3 p-2">
      <h1 className="text-center">Dienstplan DRK-EF</h1>
      <p className="text-center">
        Willkommen beim Dienstplan des DRK-Erfurt. 
        Hier kannst du deinen persönlichen Dienstplan als Internetkalender generieren und ihn in deinem Kalender einbinden. 
        Welche Anwendungen unterstützt werden und wie du deinen Kalender einbindest, erfährst du in der <a href={"/help?" + params.toString()} target='_blank'>Anleitung</a>.
      </p>
      <Form.Group>
        <Row className='mb-4'>
          <Col xs={12} sm={8}>
            <Form.Label>Name</Form.Label>
            <Typeahead
              id="name-select"
              clearButton
              flip
              onChange={setNameSelection}
              options={names}
              placeholder="Mustermann, Max"
              selected={nameSelection}
            />
          </Col>
          <Col>
            <Form.Label className='text-nowrap'>
              <span>Erinnerung in Stunden </span>
              <FontAwesomeIcon
                ref={reminder_input_help}
                onMouseEnter={() => {
                  setShowReminderTooltip(true);
                }}
                onMouseLeave={() => {
                  setShowReminderTooltip(false);
                }}
                icon={faCircleQuestion}
              />
            </Form.Label>
            <Form.Control
              type="number"
              placeholder="0"
              min="0"
              onChange={(e) => setReminderInput(e.target.value)}
              value={reminderInput}
            />
            <Overlay
              target={reminder_input_help.current}
              show={showReminderTooltip}
              placement="bottom"
            >
              <Tooltip>
                Hier kannst du eine standard Erinnerung für alle Termine
                einstellen. (z.B. <b>2</b> Stunden bevor der Dienst beginnt) Ein
                Wert von 0 deaktiviert die Erinnerung.
              </Tooltip>
            </Overlay>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col>
            <fieldset disabled={icalURL === undefined}>
              <Form.Label>iCal URL</Form.Label>
              <InputGroup>
                <Form.Control
                  placeholder="Deine iCal URL wird hier angezeigt, sobald du die oberen Felder ausgefüllt hast."
                  readOnly
                  value={icalURL ?? ''}
                  onFocus={(e) => e.target.select()}
                  ref={ical_url_field}
                />
                <Button
                  onClick={copyToClipboard}
                  className={copySuccess ? 'bg-success' : ''}
                >
                  {copySuccess ? (
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ marginLeft: '2px' }}
                    />
                  ) : (
                    <FontAwesomeIcon icon={faCopy} />
                  )}
                  <span> Kopieren</span>
                </Button>
              </InputGroup>
              <Overlay
                target={ical_url_field.current}
                show={copySuccess}
                placement="bottom"
              >
                <Tooltip>
                  Die iCal URL wurde in deine Zwischenablage kopiert!
                </Tooltip>
              </Overlay>
            </fieldset>
          </Col>
        </Row>
      </Form.Group>

      {events.length > 0 ? (
        <>
          <p className="mb-0">Vorschau</p>
          <Calendar
            localizer={localizer}
            defaultDate={new Date()}
            defaultView="month"
            events={events}
            style={{ height: '500px' }}
            views={{
              month: true,
            }}
          />
        </>
      ) : null}
    </div>
  );
}

export default IcalSetup;
