import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAndroid,
  faApple,
  faWindows,
  faGoogle,
} from "@fortawesome/free-brands-svg-icons";
import "../styles/help.scss"

function Help({ token }: { token: string }) {
  const params = new URLSearchParams();
  params.set('token', token);
  return (
    <div className="appContainer col-11 col-sm-9 col-md-8 bg-light rounded-4 m-auto mt-3 p-2">
      <h1 className="text-center">Dienstplan DRK-EF</h1>
      <p className="text-center">
        Auf dieser Seite findest du alle nützlichen Informationen, die dir helfen, den Dienstplan in deinem Kalender einzubinden.
        Die Auswahl an Anwendungen begrenzt sich hierbei nicht nur auf die unten aufgeführten, da die meisten Kalender-Apps die Möglichkeit bieten, Kalender über eine URL einzubinden.
      </p>

      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <FontAwesomeIcon icon={faGoogle} className="me-2" />
            Google Kalender (Empfohlen)
          </Accordion.Header>
          <Accordion.Body>
            Google erlaubt es dir beliebige Kalender über eine URL einzubinden. 
            Öffne dazu deinen <a href="https://calendar.google.com/calendar/" target="_blank">Google Kalender</a> und klicke auf das Plus-Zeichen neben "Weitere Kalender". 
            <img src="/help_img/Google_Calendar_1.jpg" className="img-fluid tutorial-img border" />
            Wähle dann "Über URL hinzufügen" aus.
            <img src="/help_img/Google_Calendar_2.jpg" className="img-fluid tutorial-img border" />
            Füge nun deine Kalender-URL ein, welche du <a href={"/?" + params.toString()} target="_blank">hier</a> generieren kannst.
            <img src="/help_img/Google_Calendar_3.jpg" className="img-fluid tutorial-img border" />
            Jetzt nur noch auf "Kalender hinzufügen" klicken und fertig! Der Kalender wurde zu deinem Google-Account hinzugefügt und wird automatisch synchronisiert.
            <b> Bitte beachte, dass es bis zu einem Tag dauern kann, bis Änderungen im Dienstplan in deinem Kalender sichtbar werden!</b>
            <img src="/help_img/Google_Calendar_4.jpg" className="img-fluid tutorial-img border" />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <FontAwesomeIcon icon={faAndroid} className="me-2" />
            Android Smartphones
          </Accordion.Header>
          <Accordion.Body>
            Leider bieten die meisten standard Kalender-Apps auf Android Smartphones keine Möglichkeit, Kalender über eine URL einzubinden. 
            Allerdings gibt es diverse Apps, die diese Funktion nachrüsten.
            Ich empfehle einfach den Kalender über den Google Kalender einzubinden (siehe oben), da dieser dann auch auf dem Smartphone angezeigt wird.
            Nachdem du den Kalender in deinem Google Kalender eingebunden hast, musst du ihn in deiner Kalender-App nur noch aktivieren.
            (Es kann etwas dauern, bis der neu hinzugefügte Kalender auf dem Smartphone erscheint.)
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            <FontAwesomeIcon icon={faApple} className="me-2" />
            Apple Smartphones
          </Accordion.Header>
          <Accordion.Body>
            Bei Apple Smartphones ist es relativ einfach, den Kalender einzubinden.
            Öffne dazu einfach deine Kalender-App und klicke unten auf "Kalender".
            <img src="/help_img/Apple_1.png" className="img-fluid tutorial-img" />
            Wähle dann unten links "Hinzufügen" aus.
            <img src="/help_img/Apple_2.png" className="img-fluid tutorial-img" />
            Ein Menü öffnet sich, in welchem du "Kalenderabonnement hinzufügen" auswählen musst.
            Nun musst du nur noch die URL deines Kalenders einfügen, welche du <a href={"/?" + params.toString()} target="_blank">hier</a> generieren kannst.
            <img src="/help_img/Apple_3.png" className="img-fluid tutorial-img" />
            Im letzten Schritt kannst du noch den Namen des Kalenders ändern und die Farbe auswählen.
            Jetzt ist dein Kalender fertig eingerichtet und wird automatisch synchronisiert.
            <b> Bitte beachte, dass es bis zu einem Tag dauern kann, bis Änderungen im Dienstplan in deinem Kalender sichtbar werden!</b>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            <FontAwesomeIcon icon={faWindows} className="me-2" />
            Microsoft Outlook
          </Accordion.Header>
          <Accordion.Body>
            Öffne Outlook und klicke auf den "Kalender" Tab an der linken Seite.
            Klicke dann auf "Hinzufügen" in der oberen Leiste.
            Ein Menü öffnet sich, in welchem du "Aus dem Internet..." auswählen musst.
            <img src="/help_img/Outlook_1.jpg" className="img-fluid tutorial-img border" />
            Ein neues Fenster öffnet sich, in welchem du die URL deines Kalenders einfügen musst, welche du <a href={"/?" + params.toString()} target="_blank">hier</a> generieren kannst.
            <img src="/help_img/Outlook_2.jpg" className="img-fluid tutorial-img border" />
            Im letzten Schritt musst du das Abonnement noch bestätigen. Hier kannst du unter der Option "Erweitert..." noch einstellen, wie der Kalender heißen soll.
            <img src="/help_img/Outlook_3.jpg" className="img-fluid tutorial-img border" />
            Jetzt ist dein Kalender fertig eingerichtet und wird automatisch synchronisiert.
            <b> Bitte beachte, dass es bis zu einem Tag dauern kann, bis Änderungen im Dienstplan in deinem Kalender sichtbar werden! </b>
            Alternativ kannst du Kalender in Outlook auch manuell synchronisieren, indem du auf "Senden/Empfangen" klickst und dann auf "Alle Ordner synchronisieren".
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export default Help;
