import React, { Component } from 'react';
import { Scrollbars, ScrollbarProps } from 'react-custom-scrollbars-2';

interface ColoredScrollbarProps extends ScrollbarProps {
  color: string;
}

export default class ColoredScrollbars extends Component<ColoredScrollbarProps> {

  constructor(props: ColoredScrollbarProps) {
    super(props);
    this.renderThumb = this.renderThumb.bind(this);
  }

  renderThumb({ style, ...props }: { style: React.CSSProperties; props: ColoredScrollbarProps }) {
    const thumbStyle: React.CSSProperties = {
      backgroundColor: this.props.color,
      opacity: 0.5,
      borderRadius: 6,
    };
    return (
      <div
        style={{ ...style, ...thumbStyle }}
        {...props}
      />
    );
  }

  render() {
    return (
      <Scrollbars
        renderThumbHorizontal={this.renderThumb}
        renderThumbVertical={this.renderThumb}
        {...this.props}
      />
    );
  }
}
