import { Fragment, useEffect, useState } from "react";
import { Alert, Badge, Button, Col, Modal, Row, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRemove, faKey, faAdd } from "@fortawesome/free-solid-svg-icons";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import "../styles/edit-tokens.scss"
import axios from "axios";

function EditTokens({
  className,
  token,
}: {
  className?: string;
  token: string;
}) {
  const [showEditModal, setShowEditModal] = useState(false);
  const [tokens, setTokens] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  const [newToken, setNewToken] = useState<any>({token: "", can_read: false, can_write: false});

  useEffect(() => {
    getTokens();
  }, []);

  const getTokens = () => {
    const params = new URLSearchParams();
    params.set("token", token);

    axios
      .get(window.__RUNTIME_CONFIG__.API_URL + "tokens?" + params.toString())
      .then((response: any) => {
        setTokens(response.data);
      });
  };

  const deleteToken = (token_delete: string) => {
    const params = new URLSearchParams();
    params.set("token", token);
    setLoading(true);
    setError(undefined);

    axios
      .delete(window.__RUNTIME_CONFIG__.API_URL + "tokens/" + token_delete + "?" + params.toString())
      .then((response: any) => {
        setTokens(response.data);
      })
      .catch((error) => {
        setError(error.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createToken = () => {
    const params = new URLSearchParams();
    params.set("token", token);
    setLoading(true);
    setError(undefined);

    axios
      .put(window.__RUNTIME_CONFIG__.API_URL + "tokens/" + newToken.token + "?" + params.toString(), newToken)
      .then((response: any) => {
        setTokens(response.data);
        setNewToken({token: "", can_read: false, can_write: false});
      })
      .catch((error) => {
        setError(error.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const hideEditModal = () => {
    setShowEditModal(false);
    setError(undefined);
  };

  return (
    <>
      {tokens.length > 0 ? (
        <Button
          className={className}
          onClick={() => {
            setShowEditModal(true);
          }}
        >
          <FontAwesomeIcon icon={faKey} />
          <span> Tokens bearbeiten</span>
        </Button>
      ) : (
        null
      )}

      <Modal
        show={showEditModal}
        onHide={hideEditModal}
        backdrop={loading ? "static" : undefined}
      >
        <Modal.Header closeButton={true}>
          <Modal.Title>Tokens bearbeiten</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table className="w-100 token-table">
            <thead>
              <tr>
                <th>Token</th>
                <th>Lesen</th>
                <th>Schreiben</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {tokens.map((token) => (
                <tr>
                  <td>
                    {token.token}
                  </td>
                  <td>
                    <input type="checkbox" checked={token.can_read} />
                  </td>
                  <td>
                    <input type="checkbox" checked={token.can_write}/>
                  </td>
                  <td>
                    <Button variant="danger" onClick={() => {deleteToken(token.token)}} disabled={loading || token.is_admin}>
                      <FontAwesomeIcon icon={faRemove} />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          Neuen Token erstellen:

          <InputGroup>
            <Form.Control type="text" placeholder="Token" value={newToken.token} onChange={(e) => {setNewToken({...newToken, token: e.target.value})}} />
            <ToggleButton 
              id="toggle-read" 
              type="checkbox" 
              variant="outline-primary" 
              checked={newToken.can_read} 
              onChange={(e) => {setNewToken({...newToken, can_read: e.currentTarget.checked})}} 
              value="1"
            >
              Lesen
            </ToggleButton>
            <ToggleButton 
              id="toggle-write" 
              type="checkbox" 
              variant="outline-primary" 
              checked={newToken.can_write} 
              onChange={(e) => {setNewToken({...newToken, can_write: e.currentTarget.checked})}} 
              value="2"
            >
              Schreiben
            </ToggleButton>
            <Button variant="success" onClick={createToken} disabled={loading || (!newToken.can_read && !newToken.can_write) || newToken.token.length == 0}>
              <FontAwesomeIcon icon={faAdd} className="text-white" />
            </Button>
          </InputGroup>

          {error ? (
            <Alert variant="danger">{error}</Alert>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            disabled={loading}
            onClick={hideEditModal}
          >
            Schließen
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditTokens;
