import React, { useState } from 'react';
import './styles/app.scss';
import { Routes, Route, useSearchParams } from 'react-router-dom';
import AdminDashboard from './routes/admin_dashboard';
import IcalSetup from './routes/ical';
import Help from './routes/help';
import {Login} from './routes/login';
import ColoredScrollbars from './components/colored-scrollbars';

function App() {
  const [token, setToken] = React.useState<string | undefined>(undefined);

  return (
    <ColoredScrollbars 
      color='var(--bs-white)'
      style={{ width: '100%', height: '100%' }}>
      <Routes>
        { token === undefined ? 
          <Route path='*' element={<Login setToken={setToken}/>} />
            :
          <>
            <Route path='/' element={<IcalSetup token={token}/>} />
            <Route path='/admin' element={<AdminDashboard token={token}/>} />
            <Route path='/help' element={<Help token={token}/>} />
          </>
        }
      </Routes>
    </ColoredScrollbars>
  );
}

export default App;
