import { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRemove, faSpinner } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import moment from 'moment';
import axios from "axios";

function DeleteSchedule({ className, date, token, getSchedule }: { className?: string, date: Date, token: string, getSchedule: () => void }) {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();

  moment.locale('de');

  const deleteSchedule = () => {
    setLoading(true);
    const params = new URLSearchParams();
    params.set("token", token);
    axios
      .delete(window.__RUNTIME_CONFIG__.API_URL + "schedules/" + date.getFullYear() + "/" + (date.getMonth() + 1) + "?" + params.toString())
      .then((response) => {
        setShowConfirmModal(false);
      })
      .catch((error) => {
        setError(error.response.data || error.response || error);
      }).finally(() => {
        setLoading(false);
        getSchedule();
      });
  };

  const hideConfirmModal = () => {
    setShowConfirmModal(false);
    setLoading(false);
    setError(undefined);
  };

  return (
    <>
      <Button variant="danger" className={className} onClick={() => {setShowConfirmModal(true)}}>
        <FontAwesomeIcon icon={faRemove} />
        <span> Dienstplan löschen</span>
      </Button>

      <Modal
        show={showConfirmModal}
        onHide={hideConfirmModal}
        backdrop={loading ? "static" : undefined}
      >
        <Modal.Header>
          <Modal.Title>Dienstplan löschen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-0">Möchtest du den Dienstplan vom <u>{moment(date).format('MMMM y')}</u> wirklich löschen?</p>
          {error !== undefined ? (
            <Alert variant="danger" className="mt-3 mb-0">
              <Alert.Heading>Es ist ein Fehler aufgetreten!</Alert.Heading>
              <p><i>{error.message}</i></p>
              <p className="mb-0">Bitte versuche es später erneut!</p>
            </Alert>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            disabled={loading}
            onClick={hideConfirmModal}
          >
            Schließen
          </Button>
          <Button
            variant="danger"
            disabled={loading}
            onClick={() => {
              deleteSchedule();
            }}
          >
            { loading ?
							<FontAwesomeIcon icon={faSpinner} spinPulse />
							:
							<FontAwesomeIcon icon={faRemove} />
						}
            <span> Dienstplan löschen</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteSchedule;
