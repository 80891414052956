import React, { useEffect } from 'react';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightToBracket, faSpinner } from '@fortawesome/free-solid-svg-icons';

const checkToken = async (token: string, setToken: any, errorCallback: any, callback: any) => {
  const params = new URLSearchParams();
  if (token) params.set('token', token);

  axios
    .get(window.__RUNTIME_CONFIG__.API_URL + '?' + params.toString())
    .then((response) => {
      if (response.status === 200) {
        setToken(token ?? '');
      }
    })
    .catch((error) => {
      console.log(error);
      setToken(undefined);
			errorCallback();
    })
		.finally(() => {
			callback();
		});
};

const Login = ({ setToken }: any) => {
  const [searchParams, setSearchParams] = useSearchParams();
	const [loading, setLoading] = React.useState(false);
	const [showError, setShowError] = React.useState(false);

	const input = React.useRef<HTMLInputElement>(null);

	useEffect(()=>{
		if (searchParams.get('token') === null) return;
		setLoading(true);
		checkToken(searchParams.get('token') ?? '', setToken, null, () => setLoading(false));
	}, []);

	const handleLogin = () => {
		setShowError(false);
		setLoading(true);

		checkToken(
			input.current?.value ?? '', 
			setToken, 
			() => {
				setShowError(true);
			}, 
			() => setLoading(false)
		);
	};

  return (
		<div className='d-flex flex-column justify-content-center align-items-center' style={{width: '100vw', height: '100vh'}}>
			<div className="col-11 col-sm-9 col-md-8">
				<h1 className="text-center text-white">Dienstplan DRK-EF</h1>
				<p className="text-center text-white">
					<i>Bitte gib deinen Token ein, um fortzufahren.</i>
				</p>

				<InputGroup>
					<Form.Control
						placeholder="Token"
						ref={input}
					/>
					<Button disabled={loading} onClick={handleLogin}>
						{ loading ?
							<FontAwesomeIcon icon={faSpinner} spinPulse />
							:
							<FontAwesomeIcon icon={faRightToBracket} />
						}
						<span> Login</span>
					</Button>
				</InputGroup>

				<p className={"text-center text-danger mt-3 " + (showError ? "" : "invisible")}>
					Dein Token konnte nicht verifiziert werden. Bitte überprüfe ihn und versuche es erneut.
				</p>
			</div>
		</div>
  );
};

export { Login, checkToken };
