import React, { useEffect, useState } from "react";
import "../styles/admin_dashboard.scss";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-date-picker";
import "../styles/calendar.scss";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FileUploader } from "react-drag-drop-files";
import moment from "moment";
import { Alert } from "react-bootstrap";
import DeleteSchedule from "../components/delete-schedule";
import EditDuties from "../components/edit-duties";
import EditTokens from "../components/edit-tokens";

function AdminDashboard({ token }: { token: string }) {
  const [date, setDate] = useState(new Date());
  const [schedule, setSchedule] = useState<any>({});
  const [duties, setDuties] = useState<any[]>([]);
  const [showFileUpload, setShowFileUpload] = useState(false);
  const [upload, setUpload] = useState<File | undefined>(undefined);
  const [uploadDate, setUploadDate] = useState(new Date());
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState<any>();

  useEffect(() => {
    getDuties();
  }, []);

  useEffect(() => {
    getSchedule();
  }, [date]);

  useEffect(() => {
    if (upload === undefined) return;
    const name_parts = upload.name.split(" ") || [];
    if (name_parts.length < 2) return;

    if (moment(name_parts[0] + " " + name_parts[1], "MMMM YYYY").isValid()) {
      const new_date = moment(name_parts[0] + " " + name_parts[1], "MMMM YYYY").toDate();
      setUploadDate(new_date);
    }
    else
      setUploadDate(new Date());
  }, [upload]);

  const getDuties = () => {
    const params = new URLSearchParams();
    params.set("token", token);
    axios
      .get(window.__RUNTIME_CONFIG__.API_URL + "duties?" + params.toString())
      .then((response) => {
        setDuties(response.data);
      });
  };

  const getSchedule = () => {
    const params = new URLSearchParams();
    params.set("token", token);

    axios
      .get(
        window.__RUNTIME_CONFIG__.API_URL +
          "schedules/" +
          date.getFullYear() +
          "/" +
          (date.getMonth() + 1) +
          "?" +
          params.toString()
      )
      .then((response) => {
        setSchedule(response.data);
        getDuties();
      });
  };

  const daysInMonth = () => {
    let result = [];
    for (
      let i = 1;
      i <= new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
      i++
    ) {
      result.push(<th> {i} </th>);
    }
    return result;
  };

  const parsePerson = (name: string) => {
    let result = [];
    let person = schedule[name];
    for (
      let i = 1;
      i <= new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
      i++
    ) {
      if (person[i] === undefined) {
        result.push(<td> </td>);
      } else {
        let class_names: string[] = [];
        const duty = duties[person[i].duty];

        if (duty === undefined || duty["is_unknown"]) class_names.push("text-danger");
        else if (duty.is_ignored) class_names.push("text-muted");

        result.push(
          <td className={class_names.join(" ")}> {person[i].duty} </td>
        );
      }
    }
    return result;
  };

  const hideUploadModal = () => {
    setShowFileUpload(false);
    setUpload(undefined);
    setUploadError(undefined);
  };

  const uploadFile = () => {
    if (upload === undefined) return;

    setUploading(true);
    setUploadError(undefined);

    const formData = new FormData();
    formData.append("file", upload);

    const params = new URLSearchParams();
    params.set("token", token);

    axios.put(
        window.__RUNTIME_CONFIG__.API_URL 
        + "schedules/" 
        + uploadDate.getFullYear() 
        + "/"
        + (uploadDate.getMonth() + 1)
        + "?" 
        + params.toString(), formData).then((response) => {
      getSchedule();
      hideUploadModal();
    }).catch((error) => {
      setUploadError(error);
    }).finally(() => {
      setUploading(false);
    });
  };

  return (
    <>
      <Navbar bg="light">
        <Container>
          <Navbar.Brand href="#home">Dienstplan DRK-EF</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href={"/?token=" + token}>iCal</Nav.Link>
              <DatePicker
                maxDetail="year"
                minDetail="decade"
                value={date}
                onChange={(date: any) => {
                  setDate(date);
                }}
                locale="de-DE"
                format="MM.y"
                clearIcon={null}
              />
            </Nav>
            <EditTokens className="me-3" token={token} />
            <DeleteSchedule className="me-3" date={date} token={token} getSchedule={getSchedule} />
            <Button
              variant="primary"
              onClick={() => {
                setShowFileUpload(true);
              }}
            >
              <FontAwesomeIcon icon={faUpload} />
              <span> Dienstplan hochladen</span>
            </Button>
            <EditDuties className="ms-3" token={token} duties={duties} setDuties={setDuties}/>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div>
        <Table
          striped
          bordered
          hover
          variant="dark"
          className={(Object.keys(schedule).length == 0 ? "d-none" : "") + " m-auto"}
        >
          <thead>
            <tr>
              <th>Name</th>
              {daysInMonth()}
            </tr>
          </thead>
          <tbody>
            {Object.keys(schedule).map((key, _) => (
              <tr>
                <td>{key}</td>
                {parsePerson(key)}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <Modal
        show={showFileUpload}
        onHide={hideUploadModal}
        backdrop={uploading ? "static" : undefined}
      >
        <Modal.Header closeButton={!uploading ? true : undefined}>
          <Modal.Title>Dienstplan hochladen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Bitte wähle einen Dienstplan zum hochladen aus.</p>
          <FileUploader
            handleChange={(file: any) => {
              setUpload(file);
            }}
            name="file"
            types={["XLSX"]}
            hoverTitle="Datei hochladen"
            multiple={false}
          />

          {upload !== undefined ? (
            <>
              <p className="mb-0 mt-3">Für welchen Monat soll der Dienstplan hochgeladen werden?</p>
              <DatePicker
                maxDetail="year"
                minDetail="decade"
                value={uploadDate}
                onChange={(date: any) => {
                  setUploadDate(date);
                }}
                locale="de-DE"
                format="MM.y"
                clearIcon={null}
              />
            </>
          ) : null}

          {uploadError !== undefined ? (
            <Alert variant="danger" className="mt-3 mb-0">
              <Alert.Heading>Es ist ein Fehler aufgetreten!</Alert.Heading>
              <p><i>{uploadError.message}</i></p>
              <p className="mb-0">Bitte versuche es später erneut!</p>
            </Alert>
          ) : null}
          
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={uploading}
            className="text-white"
            variant="secondary"
            onClick={hideUploadModal}
          >
            Schließen
          </Button>
          <Button
            disabled={upload === undefined || uploading}
            variant="primary"
            onClick={() => {
              uploadFile();
            }}
          >
            { uploading ?
							<FontAwesomeIcon icon={faSpinner} spinPulse />
							:
							<FontAwesomeIcon icon={faUpload} />
						}
            <span> Dienstplan hochladen</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AdminDashboard;
